import Vue from "vue";
import Router from "vue-router";
import VueTabs from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/Login");

// Suppliers
const Suppliers = () => import("@/views/suppliers/Index");
const CreateSupplier = () => import("@/views/suppliers/Create");
const EditSupplier = () => import("@/views/suppliers/Edit");
const ShowSupplier = () => import("@/views/suppliers/Show");

//Clients
const Clients = () => import("@/views/clients/Index");

// Categories
const Categories = () => import("@/views/categories/Index");
const Category = () => import("@/views/categories/View");
const CreateCategory = () => import("@/views/categories/Create");
const EditCategory = () => import("@/views/categories/Edit");
const ShowCategory = () => import("@/views/categories/Show");

// Sub Categories
const CreateSubCategory = () => import("@/views/subCategories/Create");
const EditSubCategory = () => import("@/views/subCategories/Edit");
const SubCategory = () => import("@/views/subCategories/View");
const SubCategories = () => import("@/views/subCategories/Index");
const ShowSubCategory = () => import("@/views/subCategories/Show");

const CreateAd = () => import("@/views/ads/Create");
const EditAd = () => import("@/views/ads/Edit");
const Ads = () => import("@/views/ads/Index");
const ShowAd = () => import("@/views/ads/Show");

// Sizes
const CreateSize = () => import("@/views/sizes/Create");
const EditSize = () => import("@/views/sizes/Edit");
const Sizes = () => import("@/views/sizes/Index");
const ShowSize = () => import("@/views/sizes/Show");

const CreateColor = () => import("@/views/colors/Create");
const EditColor = () => import("@/views/colors/Edit");
const Colors = () => import("@/views/colors/Index");
const ShowColor = () => import("@/views/colors/Show");

const CreateBrand = () => import("@/views/brands/Create");
const EditBrand = () => import("@/views/brands/Edit");
const Brands = () => import("@/views/brands/Index");
const ShowBrand = () => import("@/views/brands/Show");

const Orders = () => import("@/views/orders/Index");
const ShowOrder = () => import("@/views/orders/Show");
const ShowEdit = () => import("@/views/orders/Edit");

const Stock = () => import("@/views/stocks/Index");

const CreateCity = () => import("@/views/cities/Create");
const EditCity = () => import("@/views/cities/Edit");
const Cities = () => import("@/views/cities/Index");
const ShowCity = () => import("@/views/cities/Show");

// Areas
const CreateArea = () => import("@/views/areas/Create");
const EditArea = () => import("@/views/areas/Edit");
const Areas = () => import("@/views/areas/Index");
const ShowArea = () => import("@/views/areas/Show");

const CreateInvoice = () => import("@/views/invoices/Create");
// const EditInvoice = () => import("@/views/invoices/Edit");
const Invoices = () => import("@/views/invoices/Index");
const ShowInvoice = () => import("@/views/invoices/Show");

// Products
// const SupplierProduct = () => import('@/views/products/SupplierProduct')
const CreateProduct = () => import("@/views/products/Create");
const EditProduct = () => import("@/views/products/Edit");
const Products = () => import("@/views/products/Index");
// const ProductsRequests = () => import('@/views/products/ProductsRequests')
const ShowProduct = () => import("@/views/products/Show");

//Menu
// const CreateMenu = () => import('@/views/products/CreateMenu')
// const EditMenu = () => import('@/views/products/EditMenu')
// const Menu = () => import('@/views/products/Menu')
// const ShowMenu = () => import('@/views/products/ShowMenu')

Vue.use(Router);
Vue.use(VueTabs);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "الرئيسية",
      meta: {
        authGuard: true
      },
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "لوحة التحكم",
          component: Dashboard
        },

        //supplier
        {
          path: "suppliers",
          meta: {
            label: " الموردين"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الموردين",
              component: Suppliers
            },
            {
              name: "تعديل المورد",
              path: ":id/edit",
              component: EditSupplier
            },
            {
              path: "create",
              name: "إضافة مورد جديد ",
              component: CreateSupplier
            },
            {
              name: "عرض مورد",
              path: ":id/show",
              component: ShowSupplier
            }
          ]
        },

        //Clients
        {
          path: "clients",
          name: "العملاء",
          component: Clients
        },
        {
          path: "stock",
          name: "المخزن",
          component: Stock
        },
        //categories
        {
          path: "categories",
          meta: {
            label: "الفئات"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الفئات الأساسية",
              component: Categories
            },
            {
              name: "تعديل الفئة",
              path: ":id/edit",
              component: EditCategory
            },
            {
              path: "create",
              name: "إضافة فئة جديدة",
              component: CreateCategory
            },
            {
              path: ":id/show",
              name: "عرض الفئة الأساسية",
              component: ShowCategory
            }
          ]
        },
        //subCategory
        {
          path: "sub-categories",
          meta: {
            label: "الفئة الفرعية"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الفئات الفرعية",
              component: SubCategories
            },
            {
              path: "create",
              name: "إضافة فئة فرعية جديدة",
              component: CreateSubCategory
            },
            {
              name: "تعديل الفئة الفرعية",
              path: ":id/edit",
              component: EditSubCategory
            },
            {
              name: "عرض الفئة الفرعية",
              path: ":id/show",
              component: ShowSubCategory
            }
          ]
        },
        {
          path: "ads",
          meta: {
            label: "الإعلانات"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الإعلان",
              component: Ads
            },
            {
              path: "create",
              name: "إضافة إعلان جديد",
              component: CreateAd
            },
            {
              name: "تعديل إعلان",
              path: ":id/edit",
              component: EditAd
            },
            {
              name: "عرض إعلان",
              path: ":id/show",
              component: ShowAd
            }
          ]
        },
        //Invoices
        {
          path: "invoices",
          meta: {
            label: "الفواتير"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الفواتير",
              component: Invoices
            },
            {
              path: "create",
              name: " إضافة فاتورة جديدة",
              component: CreateInvoice
            },
            //   {
            //     name: "تعديل الفاتورة",
            //     path: ":id/edit",
            //     component: EditInvoice,
            //   },
            {
              name: "عرض الفاتورة",
              path: ":id/show",
              component: ShowInvoice
            }
          ]
        },
        //Sizes
        {
          path: "sizes",
          meta: {
            label: "الأحجام"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الأحجام",
              component: Sizes
            },
            {
              path: "create",
              name: " إضافة حجم جديدة",
              component: CreateSize
            },
            {
              name: "تعديل الحجم",
              path: ":id/edit",
              component: EditSize
            },
            {
              name: "عرض الحجم",
              path: "",
              component: ShowSize
            }
          ]
        },
        //Colors
        {
          path: "colors",
          meta: {
            label: "الألوان"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الألوان",
              component: Colors
            },
            {
              path: "create",
              name: " إضافة لون جديدة",
              component: CreateColor
            },
            {
              name: "تعديل اللون",
              path: ":id/edit",
              component: EditColor
            },
            {
              name: "عرض اللون",
              path: "",
              component: ShowColor
            }
          ]
        },
        //Brands
        {
          path: "brands",
          meta: {
            label: "الماركات"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "الماركات",
              component: Brands
            },
            {
              path: "create",
              name: " إضافة ماركة جديدة",
              component: CreateBrand
            },
            {
              name: "تعديل الماركة",
              path: ":id/edit",
              component: EditBrand
            },
            {
              name: "عرض الماركة",
              path: "",
              component: ShowBrand
            }
          ]
        },
         //Brands
         {
          path: "orders",
          meta: {
            label: "الطلبات"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "",
              component: Orders
            },
            // {
            //   path: "create",
            //   name: " إضافة ماركة جديدة",
            //   component: CreateBrand
            // },
            {
              name: "تعديل حالة الطلب",
              path: ":id/edit",
              component: ShowEdit
            },
            {
              name: "عرض الطلب",
              path: ":id/show",
              component: ShowOrder
            }
          ]
        },
        //Cities
        {
          path: "cities",
          meta: {
            label: "المدن"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "المدن",
              component: Cities
            },
            {
              path: "create",
              name: " إضافة مدينة جديدة",
              component: CreateCity
            },
            {
              name: "تعديل المدينة",
              path: ":id/edit",
              component: EditCity
            },
            {
              name: "عرض المدينة",
              path: "",
              component: ShowCity
            }
          ]
        },
        //Areas
        {
          path: "areas",
          meta: {
            label: "المناطق"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "المناطق",
              component: Areas
            },
            {
              path: "create",
              name: " إضافة منطقة جديدة",
              component: CreateArea
            },
            {
              name: "تعديل المنطقة",
              path: ":id/edit",
              component: EditArea
            },
            {
              name: "عرض المنطقة",
              path: ":id/show",
              component: ShowArea
            }
          ]
        },
        //Products

        {
          path: "products",
          meta: {
            label: "المنتجات"
          },
          component: {
            render(c) {
              return c("router-view");
            }
          },
          children: [
            {
              path: "",
              name: "المنتجات",
              component: Products
            },
            {
              path: "create",
              name: "إضافة منتج جديد",
              component: CreateProduct
            },

            {
              name: "تعديل المنتج",
              path: ":id/edit",
              component: EditProduct
            },
            {
              name: " عرض المنتج  ",
              path: ":id/show",
              component: ShowProduct
            }
            //Menu
            // {
            //     name: ' إضافة قائمة ',
            //     path: 'create-menu',
            //     component: CreateMenu
            // },
            // {
            //     name: 'تعديل القائمة',
            //     path: ':id/edit-menu',
            //     component: EditMenu
            // },
            // {
            //     name: 'القائمة',
            //     path: 'menu',
            //     component: Menu
            // },
            // {
            //     name: ' عرض القائمة',
            //     path: ':id/show-menu',
            //     component: ShowMenu
            // },
          ]
        }
      ]
    },
    {
      path: "/login",
      component: Login,
      name: "Login"
    }
  ];
}
