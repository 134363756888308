import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCsrf from 'vue-csrf';
import jquery from 'jquery'
import vSelect from 'vue-select'
import _ from 'lodash';
import VueSession from 'vue-session'
import loading from 'vuejs-loading-screen'
import Multiselect from 'vue-multiselect'
import VueHtmlToPaper from 'vue-html-to-paper';

import 'datatables.net-bs4'
// import buttons and plugins
import 'datatables.net-buttons/js/dataTables.buttons.js'
//jszip
import 'datatables.net-buttons/js/buttons.flash.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
// import the rest
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'
import { numberFormat } from "././assets/js/number-format";
import swal from 'sweetalert'


Vue.config.performance = true;
Vue.use(CoreuiVue, VueAxios, axios, VueCsrf, jquery, VueSession, loading, Multiselect);
const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ],
    styles: [
      'https://cdn.rtlcss.com/bootstrap/v4.5.3/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css'
    ]
  }
Vue.use(VueHtmlToPaper, options);
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$http = axios;
Vue.use(loading);
Vue.prototype.$ = jquery;
Vue.prototype._ = _;
Vue.prototype.$session = VueSession;
Vue.component('v-select', vSelect);
Vue.component('multiselect', Multiselect)

Vue.prototype.$hostname = 'http://localhost:8080/'
Vue.filter("number", function(value) {
    return numberFormat(value, 0);
});

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    },
    data: {
        token: null,
        categories: 0,
        subCategories: 0
    },
    methods: {
    }
});
axios.interceptors.request.use(req => {
    jquery('.loading').show()
    req.headers.authorization = 'Bearer ' + localStorage.token
    return req
  });
  axios.interceptors.response.use(function (response) {
    jquery('.loading').hide()
    return response
  }, function (error) {
    jquery('.loading').hide()
    console.log(error)
    if (error.response.status === 401) {
      swal({
        title: 'انتهت الجلسة',
        text: 'انتهت صلاحية جلسة العمل الخاصة بك. هل ترغب في إعادة توجيهك إلى صفحة تسجيل الدخول؟',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Yes',
        closeOnConfirm: false
      }).then((oKBtn) => {
        window.location = '/login'
      })
    } else {
      return Promise.reject(error)
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.authGuard) && !localStorage.token) {
        next({ name: 'Login' });
    }
    // if the user is not authenticated, `next` is called twice
    next();
});
Vue.mixin({
    methods: {
      postRequest: function (formData, url, create) {
        // this.$isLoading(true)
        this.$http.post(`${url}`, formData)
          .then((response) => {
            if (!response.data.error) {
              swal({ title: response.data.message, buttons: 'تم' })
              if (create) {
                this.$router.push({ path: create })
              }
            } else {
              swal({ title: response.data.message, buttons: 'تم' })
            }
          }).catch(function (error) {
            if (Object.prototype.hasOwnProperty.call(error, 'response')) {
              const data = (error.response || {}).data
              if (data.error) {
                swal({ title: data.message, buttons: 'تم' })
              } else {
                swal({
                  title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
                  buttons: 'تم'
                })
              }
            } else {
              swal({
                title: 'حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا',
                buttons: 'تم'
              })
            }
          })
      }
    }
  })
  