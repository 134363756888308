<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<style>
@import url('https://cdn.datatables.net/1.10.24/css/jquery.dataTables.min.css');
</style>

<style>

@import url('https://fontlibrary.org/face/droid-arabic-kufi');

</style>
<style>
.flex-wrap {
    margin-bottom: 5px;
}
.c-sidebar-nav-item {
    width: 90%;
}
.invalid-feedback {
  display: inherit;
}
</style>
